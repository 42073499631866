import { Button, Popconfirm, message,Input, Radio, Select, Upload,} from "antd";
import { FaStar, FaListUl, FaFileUpload, FaEnvelope, FaTextHeight } from 'react-icons/fa';
import { observer } from "mobx-react-lite";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Drawers, Forms, Spinners } from "../../../components";
import { Store } from "../../../stores/stores";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "./style.css";
import { SurveyStore } from "../../../stores/survey";
import { ISurvey, ISurveyQuestion, ISurveyQuestionType } from "../../../utils/interfaces";
import { SurveyQuestionTypeStore } from "../../../stores/survey-type-question";
import { TypeId } from "../../../utils/enums";
import { SurveyQuestionStore } from "../../../stores/survey-question";
import QuestionModal from "../../../components/Modals/QuestionModal";
import { title } from "process";
import { FieldNumberOutlined, UploadOutlined } from "@ant-design/icons";

export const Builder = observer(() => {
    const [value, setValue] = React.useState(1);
    const [selectedClosingMsg, setSelectedClosingMsg] = React.useState('');
    const [selectedOpeningMsg, setSelectedOpeningMsg] = React.useState('');
    const [currentQuestion, setCurrentQuetsion] = React.useState<ISurveyQuestion>();
    const [surveylocalQuestion,setSurveyLocalQuestion] = React.useState<ISurveyQuestion[]>([]);
    const [,setSurveyLocalQuestionType] = React.useState<ISurveyQuestionType[]>([]);
    const [surveyText,setSurveyText] = React.useState<ISurvey[]>([]);
    const [questionModalVisible, setQuestionModalVisible] = React.useState(false)
    const [selectedValue, setSelectedValue] = React.useState(0);

    const { surveyStore, surveyQuestionType, surveyQuestion} = Store;
    const { Option } = Select

    React.useEffect(() => {
        if (!surveyStore.surveyLoaded && !surveyStore.isLoading) {
        surveyStore.loadSurvey();
        }
    }, [surveyStore]);

    React.useEffect(()=>{
        setSurveyLocalQuestion(surveyQuestion.surveyQuestions.value.data.slice())
    },[surveyQuestion.surveyQuestions.value.data])

    React.useEffect(()=>{
        setSurveyLocalQuestionType(surveyQuestionType.hrQuestionTypes.value.data)
    },[surveyQuestionType.hrQuestionTypes.value.data])

   React.useEffect(()=>{
     setSurveyText(surveyStore.hrSurveys.value.data);
   },[surveyStore.hrSurveys.value.data])

    React.useEffect(() => {
    if (surveyStore.currentSurvey) {
        surveyQuestion.setSurveyQuestions(surveyStore.currentSurvey.surveyQuestions);
        setSelectedClosingMsg(surveyStore.currentSurvey.closingMessage);
        setSelectedOpeningMsg(surveyStore.currentSurvey.openingMessage);
    }
    }, [surveyStore.currentSurvey, surveyQuestion]);

    React.useEffect(() => {
        if (surveyStore.currentSurvey) {
          const index = surveyText.findIndex((x)=>x.id === surveyStore.currentSurvey?.id)
          if (index >=0){
            setSelectedValue(index);
          }
        }
    }, [surveyStore.currentSurvey, surveyText]);
    
    React.useEffect(()=>{
        surveyQuestionType.loadQuestionTypes();
    }, [surveyQuestionType])


    const onRadioChange = (e: any) => {
        setValue(e.target.value);
    };

    const showQuestionModal = (q: any) => {
        console.log("check q",q)
        setCurrentQuetsion(q)
        setQuestionModalVisible(true)
    }

    const addNewQuestion = async (type_id: TypeId) => {
    if (surveyStore.currentSurvey) {
        let qcontent;
        let options: string[] | undefined;

        switch(type_id) {
            case TypeId.Text:
                qcontent = { title: "Ask Question here" };
                break;
            case TypeId.MultipleChoice:
            case TypeId.MultiSelect:
                qcontent = { title: "Ask Multiple Question here" };
                options = []; // Options will be assigned an empty array
                break;
            case TypeId.Email:
                surveyQuestion.surveyQuestions.value.data.find((x)=>x.text)
                qcontent = { title: "New Email Question" };
                break;
            case TypeId.Document:
                qcontent = { title: "New Document Question" };
                break;
            case TypeId.Number:
                qcontent = { title: "New Number Question" };
                break;
            default:
                console.error('Invalid type_id');
                return;
        }

        let question: ISurveyQuestion = {
            questionTypeId: type_id, 
            text: (qcontent.title), 
            surveyId: surveyStore.currentSurvey.id, 
            position: surveyQuestion.surveyQuestions.value.data.length,
            options: options 
        }as ISurveyQuestion;

        surveyQuestion.addNewSurveyQuestion(question);
        await surveyStore.loadSurvey();
    } else {
        message.warning("Please select a survey");
    }
}

    const getIconComponent = (type_id: TypeId) => {
    switch (type_id) {
        case TypeId.Text:
            return <FaTextHeight/>;
        case TypeId.MultipleChoice:
        case TypeId.MultiSelect:
            return <FaListUl />;
        case TypeId.Email:
            return <FaEnvelope />;
        case TypeId.Document:
            return <FaFileUpload />;
        case TypeId.Number:
            return <FieldNumberOutlined />; // Replace YourNumberIconComponent with the actual component for the number icon from the same family
        default:
            console.error('Invalid type_id');
            return null;
    }
};



    
    const sortQuestion = (res:any)=>{
        const items = Array.from(surveyQuestion.surveyQuestions.value.data)
        const [sortedItems] = items.splice(res.source.index,1)
        items.splice(res.destination.index,0,sortedItems)
        //Make a call for each question and update the position
        // eslint-disable-next-line array-callback-return
        items.map((q,inx)=>{
            q.position = inx; 
            surveyQuestion.updateSurveyQuestion(q.id,q)
        })
        // setQuestions(items)
        surveyQuestion.setSurveyQuestions(items)
    }

    const renderElement = (q: { questionTypeId: string; text: string | null; options: string[] | undefined; }) => {
    console.log(`Rendering element with questionTypeId: ${q.questionTypeId}`);
    if (q.questionTypeId === TypeId.Text) {
        return <Input.TextArea rows={3} /> 
    }
    else if (q.questionTypeId === TypeId.MultipleChoice) {
        return (
            <Radio.Group onChange={onRadioChange} className='multi-choice' value={value}>
                {q.options?.map((m:any, inx:any) => (
                    <Radio key={inx} value={m}>{m}</Radio>
                ))}
            </Radio.Group> 
        )
    }
    else if (q.questionTypeId === TypeId.MultiSelect) {
        return (
            <Select 
                mode="tags" 
                size={"large"} 
                style={{ width: '100%', height: "60px" }} 
                placeholder="Select an item or type something new and press enter"
            >
                {q.options?.map((option: string, index: number) => (
                    <Select.Option key={index} value={option}>{option}</Select.Option>
                ))}
            </Select>
        )
    }
    else if (q.questionTypeId === TypeId.Email) {
        return <Input type="email"  className='element'/> 
    }
    else if (q.questionTypeId === TypeId.Document) {
        return <Upload ><Button icon={<UploadOutlined />}>Click to Upload</Button></Upload>
    }
    else if (q.questionTypeId === TypeId.Number) {
        return <Input type="number"  className='element' /> 
    }
    else {
        return <button>404</button>
    }
}
        const confirmDeleteQuestion = async (e:any, q:ISurveyQuestion) => {  
                try {
                await surveyQuestion.deleteSurveyQuestion(q);
                surveyQuestion.setSurveyQuestions(surveyQuestion.surveyQuestions.value.data.filter(item => item.id !== q.id));
            message.success("Question deleted");
        } catch (e:any) {
            console.log(e.message);
        }
    };

    const handleSelectSurvey = (index: number) => {
     setSelectedValue(index); 
     surveyStore.selectSurvey(index);
    }

    return (
        <div>
            <DragDropContext onDragEnd={sortQuestion}>
                <Droppable droppableId="droppable-1" type="QUESTION">
                    {(provided, snapshot) => (
                        <div id="survey-root" >
                            <div className='question-type-list'>
                                <div className='add-question-text'>
                                    Add question
                                </div>
                                {surveyQuestionType.hrQuestionTypes.value.data.map((type : ISurveyQuestionType, i: number) => (
                                    <div onClick={() => addNewQuestion(type.id as TypeId)} className='q-type-contain'>
                                        <div className='question-type-box'>
                                            {getIconComponent(type.id as TypeId)}
                                        </div>
                                        <div className='type-name'>{type.name}</div>
                                    </div>
                                ))}
                                {provided.placeholder}
                            </div>

                            <div className='survey-section' >
                                <div className='survey-controls'>
                                    <Select size={'large'} onChange={handleSelectSurvey} placeholder="Select Survey" className='survey-select' value={selectedValue}>
                                        {surveyText.map((surv, inx) => (
                                            <Option key={inx} value={inx} >{surv.name}</Option>
                                        ))}
                                    </Select>
                                  


                                    {
                                     <QuestionModal question={currentQuestion} visible={questionModalVisible} setVisible={setQuestionModalVisible} />
                                    // <Modals.SurveyMessageModal visible={surveyMessModalVisible} setSurvey={surveyStore.selectSurvey} setVisible={setSurveyMessModalVisible} survey={surveyStore.currentSurvey} />
                                    }
                                </div>

                                <div {...provided.droppableProps} ref={provided.innerRef}
                                // changes bgcolor when is being dragged
                                style={{ backgroundColor: snapshot.isDraggingOver ? '#1D394852' : '#fff' }}
                                    {...provided.droppableProps} className='survey-droppable-area'>
                                    <div key={-200} className='welcome-closing-messsage' >
                                        <span className='opening-msg'>
                                            {/* survey opening message */}
                                            {selectedOpeningMsg}
                                        </span>
                        
                                    </div>
                                    {surveyQuestion.surveyQuestions.value.data.map((q: ISurveyQuestion, i: number) => (
                                        // surround every question item with draggable
                                        <Draggable index={i} key={q.id + "-order"} draggableId={String(q.id) + "-order"} >
                                            {(provider) => (
                                                <div key={q.id + "-key"} {...provider.draggableProps} {...provider.dragHandleProps} ref={provider.innerRef} className='question' >
                                                    {/* question-top is title plus buttons */}
                                                    <div className='question-top'>
                                                        {
                                                         q.questionTypeId === TypeId.Header ?
                                                        // check for headers and render them with a different color
                                                             <span className='question-title header'> {q.text}</span>
                                                                 :
                                                              <span className='question-title'> {q.text}</span>
                                                        }
                                                        <span className='question-controls'>
                                                            <Buttons.Text
                                                             text='Edit'
                                                             onClick={() => showQuestionModal(q)} 
                                                            /> 
                                                            <Popconfirm
                                                                title="Delete this question?"
                                                                onConfirm={(e) => confirmDeleteQuestion(e, q)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Buttons.Text text='Delete'/>
                                                            </Popconfirm>
                                                        </span>
                                                    </div>

                                                    {/* question-element is textbox/rating/dropdown */}
                                                    <div className="question-element">
                                                        {renderElement(q)}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    <div key={-201} className='welcome-closing-messsage' >
                                        <span className='opening-msg'>
                                            {selectedClosingMsg}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
});
