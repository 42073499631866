import { ParcelItem } from "../../components/ExpandedTables/ProductSpecificationTable";
import { APP_URL } from "../../config/axios-config";
import { IOrder, IParcelLabel } from "../../utils/interfaces";
import { Pagination, ResponseFormat } from "../../utils/types";

export const getOrders = async (queryParameters?: string): Promise<ResponseFormat<IOrder>> => {
    const response = await APP_URL.get('order?' + queryParameters || '');


    let orders: IOrder[] = [];
    let pagination: Pagination = null;

    if (response?.data) {
        orders = response.data;
    }

    if (response.headers["x-pagination"]) {
        pagination = JSON.parse(response.headers["x-pagination"]);
    }

    return { data: orders, pagination: pagination };
}

export const getOrder = async (id: string): Promise<IOrder> => {
    const response = await APP_URL.get(`order/${id}`);

    let item: IOrder = response.data;
    return item;
}

export const addOrder = async (body: IOrder, order: IOrder[]): Promise<ResponseFormat<IOrder>> => {
    const response = await APP_URL.post(`order`, body);

    order.push(response.data as IOrder);
    return { data: order };
}

export const updateOrder = async (body: IOrder, order: IOrder[]): Promise<ResponseFormat<IOrder>> => {
    order = [...order]

    await APP_URL.put(`order/${body.id}?statusCode=${body.statusCode}`, body);

    const elementPos = order.map(x => x.id).indexOf(body.id);
    order[elementPos!] = body;
    return { data: order };
}

export const deleteOrder = async (body: IOrder, order: IOrder[]): Promise<ResponseFormat<IOrder>> => {
    await APP_URL.delete(`order/${body.id}`);

    return { data: order };
}

export const createParcel = async (orderId: string, parcels: ParcelItem[]): Promise<void> => {
    const requestBody = parcels.map(x => ({
        "parcelNo": +x.parcelNo,
        "items": +x.items,
        "lengthInM": +x.length,
        "widthInM": +x.width,
        "heightInM": +x.height,
        "weightInKg": +x.weight,
    }));

    await APP_URL.post(`order/logistics/createParcel/${orderId}`, requestBody);
}

export const getLabels = async (waybill: string): Promise<ResponseFormat<IParcelLabel>> => {
    const response = await APP_URL.get(`order/logistics/label/${waybill}`);

    const items: IParcelLabel[] = response.data;
    return { data: items };
}

export const getLabelaryImage = async (zpl: string): Promise<Blob> => {
    const dpmm = '8dpmm', width = 3.93701, height = 3.93701, index = 0;
    const url = `https://api.labelary.com/v1/printers/${dpmm}/labels/${width}x${height}/${index}/`
    const response = await APP_URL.post(`/order/logistics/labelary`, { url, zpl }, {
        responseType: 'blob'
    });
    return response.data;
}
