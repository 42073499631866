import { observable, action, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { addSurveyQuestion,getSurveyQuestion, deleteSurveyQuestion, getSurveyQuestions, updateSurveyQuestions, updateSurveyQuestion } from "../services/api-service/survey-question";
import { ISurveyQuestion } from "../utils/interfaces";

export class SurveyQuestionStore {
    surveyQuestions = new LoadManager<ISurveyQuestion>({ data: [] }, getSurveyQuestions, addSurveyQuestion, deleteSurveyQuestion, updateSurveyQuestions);
    SurveyQuestion: ISurveyQuestion | null = null;
    
    constructor() {
        makeObservable(this,{
            surveyQuestions: observable,
            SurveyQuestion: observable,
            setSurveyQuestions:action,
            updateSurveyQuestion: action,
            loadSurveyQuestions: action,
            loadSurveyQuestion: action,
            updateSurveyQuestions:action,
            deleteSurveyQuestion: action,
        })
    }

    async loadSurveyQuestions() {
        await this.surveyQuestions.fetch()
    }

    async loadSurveyQuestion(id: string) {
        try {
            this.SurveyQuestion = await getSurveyQuestion(id);
        } catch (e) {
            console.log('Error fetching survey question', e);
        }
    }

    async updateSurveyQuestion(id: string, question: ISurveyQuestion) {
        try {
            await updateSurveyQuestion(id, question);
            this.loadSurveyQuestions(); 
        } catch (e) {
            console.error('Error updating survey question', e);
        }
    }

    async updateSurveyQuestions(question: ISurveyQuestion) {
        try {
          
            await this.surveyQuestions.update(question);
            this.loadSurveyQuestions();
        } catch (e) {
            console.log('Error updating survey question', e);
        }
    }

    
    async deleteSurveyQuestion(body: ISurveyQuestion) {
        try {
            await this.surveyQuestions.remove(body);
            this.loadSurveyQuestions();
        } catch (e) {
            console.log('Error deleting survey question', e);
        }
    }

    setSurveyQuestions(questions: ISurveyQuestion[]): void {
    this.surveyQuestions.value.data = questions;
  }

    
    async addNewSurveyQuestion(question: ISurveyQuestion) {
        try {
            await this.surveyQuestions.add(question);
        } catch (e) {
            console.log('Error adding survey question', e);
        }
    }
}
