import { Input, Table, DatePicker } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Drawers, Spinners } from "../../../components";
import { Store } from "../../../stores/stores";
import "./style.css";
import { IClient, IDeliveryOrder, IOrder } from "../../../utils/interfaces";
import { OrderStatus } from "../../../services";
import { StatusCodes } from "../../../utils/enums";
import moment from 'moment';



export const Orders = observer(() => {
  const { notificationsStore, ordersStore, payStore } = Store;

  const { getOrders } = ordersStore;
  const orders = getOrders.data;
  const pagination = ordersStore.pagination;

  const comporeStrings = (a: string, b: string) => {
    return a.localeCompare(b);
  }

  const handleOpenTransactions = async () => {
    await payStore.loadPaystackTransactions();
  }

  const columns = [
    {
      title: 'Invoice Number',
      dataIndex: 'refNo',
      fixed: 'left' as 'left',
      width: 150,
      sorter: (a: IOrder, b: IOrder) => comporeStrings(a.refNo, b.refNo),
      render: (text: any, record: IOrder) => {
        if (record.statusCode !== StatusCodes.RECEIVED) {
          return <div>#{text}</div>;
        }
        const today = moment();
        const dateOrdered = moment(record.dateOrdered);
        const diff = today.diff(dateOrdered, 'days');
        let color: string = "#53B175"; // default green
        if (diff === 0) {
          color = '#53B175'; // green
        } else if (diff === 1) {
          color = '#FFB800'; // yellow
        } else {
          color = '#D8232A'; // red
        }
        return <div style={{ color }}>#{text}</div>;
      },
    },
    {
      title: 'Order Date',
      dataIndex: 'dateOrdered',
      key: "date",
      render: (text: any, record: IOrder) => (
        <div>{moment(record.dateOrdered).format('YYYY-MM-DD')}</div>
      )
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: "clientName",
      render: (text: IClient, record: any) => (
        <div>{text?.userAuth?.firstName} {text?.userAuth?.lastName}</div>
      )
    },
    {
      title: 'Contact',
      dataIndex: 'client',
      key: "clientContact",
      render: (text: IClient, record: any, index: any) => (
        <div>{text.billingPhone}</div>
      )
    },
    {
      title: 'Order Status',
      dataIndex: 'statusCode',
      filters: OrderStatus.orderStatusInfo.map((x) => ({
        text: x.readableText,
        value: x.statusCode
      })),
      onFilter: (value: any, record: IOrder) => record?.statusCode.indexOf(value) === 0,
      render: (text: StatusCodes) => {
        const statusInfo = OrderStatus.getStatusInfo(text);
        return (
          <div style={{ color: statusInfo?.color }}>
            {statusInfo?.readableText}
          </div>
        )
      }
    },
    {
      title: 'Courier',
      dataIndex: 'deliveryOrders',
      sorter: (a: IOrder, b: IOrder) =>
      comporeStrings(a.deliveryOrders[0].delivery.refNo, b.deliveryOrders[0].delivery.refNo),
      render: (text: IDeliveryOrder[], record: any) => (
        <div>{text[0]?.delivery?.refNo}</div>
      )
    },
    {
      title: 'Price',
      dataIndex: 'grandTotal',
      sorter: (a: IOrder, b: IOrder) => a.grandTotal - b.grandTotal,
      render: (text: number, record: any) => "R" + (text?.toFixed(2) ?? '--')
    },
    {
      key: 'edit',
      fixed: 'right' as 'right',
      width: 60,
      render: (text: any, record: IOrder) => (
        <Buttons.Icon
          onClick={() => {
            ordersStore.editOrder(record);
            notificationsStore.loadOrderMessages(record.id);
          }}
        />
      )
    },
  ];

  useEffect(() => {
    ordersStore.loadOrders(ordersStore.defaultPageNumber, ordersStore.defaultPageSize);
}, [ordersStore.query, ordersStore.dateRange, ordersStore]);


  return (
    <main id="home" className='home-content-page'>
      <div className='pageheader-container'>
        <h1 className='header'>Orders</h1>
        <div className='pageheader-options'>
          <div className="pageheader-seperator">
            <Buttons.Small
              className='dropdown-btn'
              text="Transactions"
              onClick={() => handleOpenTransactions()}
            />
          </div>

          <DatePicker.RangePicker
            className='orders-date-picker'
            defaultValue={ordersStore.dateRange as any}
            disabled={ordersStore.isLoading}
            onChange={(value) => ordersStore.setDateRange(value)}
          />

          <Input
            size='large'
            className='pageheader-options-search orders-search'
            placeholder="Search list..."
            defaultValue={ordersStore.query}
            prefix={<RiSearchLine color='#CBD5E0' />}
            // disabled={ordersStore.isLoading}
            onChange={(_) => {
              ordersStore.setQuery(_.target.value)
            }}
          />
        </div>
      </div>

      <div className='custom-table-container'>
        <Spinners.CustomSpinner
          title={'Getting Orders'}
          isSpinning={ordersStore.isLoading}
        >
          <Table
            dataSource={[...orders]}
            columns={columns}
            className='custom-table'
            scroll={{ x: 3500 }}
            rowKey='id'
            pagination={{
              pageSize: pagination.PageSize,
              current: pagination.PageNumber,
              total: pagination.TotalRecords,
              showSizeChanger: true,
              pageSizeOptions: ["50" ,"100", "500", "1000"],
              style: { margin: '16px' },
              onChange: (page, size) => {
                  ordersStore.loadOrders(page, size);
              },
              onShowSizeChange: (current, size) => {
                ordersStore.loadOrders(current, size);
            }
          }}
          />
        </Spinners.CustomSpinner>
      </div>

      <Drawers.TransactionDrawer />
    </main>
  );
});

