import { APP_URL } from "../../config/axios-config";
import { IBranch } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getBranches = async (): Promise<ResponseFormat<IBranch>> => {
    const response = await APP_URL.get('branch');

    let branches: IBranch[] = [];

    if (response?.data) {
        branches = response.data;
    }
    return {
        data: branches
    };
}

export const addBranch = async (body: IBranch, branches: IBranch[]): Promise<ResponseFormat<IBranch>> => {
    const response = await APP_URL.post('branch', body);

    branches.push(response.data as IBranch);

    return { data: branches };
}

export const updateBranch = async (body: IBranch, branches: IBranch[]): Promise<ResponseFormat<IBranch>> => {
    branches = [...branches]

    await APP_URL.put(`branch/${body.id}`, body);

    const elementPos = branches.map(x => x.id).indexOf(body.id);
    branches[elementPos!] = body;

    return { data: branches };
}

export const deleteBranch = async (body: IBranch, branches: IBranch[]): Promise<ResponseFormat<IBranch>> => {
    await APP_URL.delete(`branch/${body.id}`);

    return { data: branches };
}