import { observable, action, computed, makeObservable, runInAction } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { getCategories, addCategory, deleteCategory, updateCategory } from "../services/api-service/category";
import { IAttribute, ICategory, IInventoryMaster, IMessage, ISkuMaster, IStockUom, IWarehouseInventory } from "../utils/interfaces";
import { message } from "antd";
import { getSkuMasters, addSkuMaster, deleteSkuMaster, updateSkuMaster } from "../services/api-service/sku-master";
import { getInventoryMasters, addInventoryMaster, deleteInventoryMaster, updateInventoryMaster, updateEditorContent } from "../services/api-service/inventory";
import { addAttribute, deleteAttribute, getAttributes, updateAttribute } from "../services/api-service/attribute";
import { DebounceSelectProp } from "../components/Search/DebounceSearch";
import { APIService } from "../services";
import { addStockUom, deleteStockUom, getStockUoms, updateStockUom } from "../services/api-service/stock-uoms";
import { addProductClass, deleteProductClass, getProductClasses, updateProductClass } from "../services/api-service/product-class";
import { isElement } from "react-dom/test-utils";


export class InventoryStore {
    skuMasters = new LoadManager<ISkuMaster>({ data: [] }, getSkuMasters, addSkuMaster, deleteSkuMaster, updateSkuMaster);
    categories = new LoadManager<ICategory>({ data: [] }, getCategories, addCategory, deleteCategory, updateCategory);
    inventory = new LoadManager<IInventoryMaster>({ data: [] }, getInventoryMasters, addInventoryMaster, deleteInventoryMaster, updateInventoryMaster);
    attributes = new LoadManager<IAttribute>({ data: [] }, getAttributes, addAttribute, deleteAttribute, updateAttribute);
    stockUoms = new LoadManager<IStockUom>({ data: [] }, getStockUoms, addStockUom, deleteStockUom, updateStockUom);
    productClasses = new LoadManager<IStockUom>({ data: [] }, getProductClasses, addProductClass, deleteProductClass, updateProductClass);

    query = "";
    attributeQuery = "";
    showSkuPanel = false;
    showInventoryPanel = false;
    currentCategory?: ICategory;
    currentSKUMaster?: ISkuMaster;
    currentInventory?: IInventoryMaster;
    currentWarehouseInventory: IWarehouseInventory[] = [];
    categoriesData: ICategory[] = [];
    isCategoriesLoading: boolean = false;
    isLoadingWareHouseInvetory: boolean = false;
    searchId = 0;
    isSearching = false;

    constructor() {
        makeObservable(this, {
            skuMasters: observable,
            categories: observable,
            categoriesData:observable,
            inventory: observable,
            attributes: observable,
            isCategoriesLoading:observable,
            stockUoms: observable,
            productClasses: observable,
            query: observable,
            attributeQuery: observable,
            loadSubcategories: action,
            setQuery: action,
            setCategory: action,
            editCategory:action,
            setSKUMaster: action,
            setInventory: action,
            addNewCategory:action,
            showSkuPanel: observable,
            showInventoryPanel: observable,
            currentCategory: observable,
            currentSKUMaster: observable,
            currentInventory: observable,
            currentWarehouseInventory: observable,
            skuPanelTitle: computed,
            invPanelTitle: computed,
            isNewSku: computed,
            addNewSku: action,
            addNewInventory: action,
            editSku: action,
            editInventory: action,
            getCategories:action,
            isLoading: computed,
            isLoadingInventory: computed,
            isLoadingWareHouseInvetory: observable,
            loadCategories: action,
            loadSKUs: action,
            removeSKUs: action,
            loadInventory: action,
            removeInventory: action,
            loadWarehouseInventory: action,
            searchAtrribute: action,
            searchCategory: action,
            searchComplementaryItems: action,
            resetSkuForm: action,
            resetInventoryForm: action,
            getParentCategory:action,
            onSKUFormSubmit: action,
            categoryList: computed,
            skuSearchList: computed,
            inventoryList: computed,
            isSearching: observable,
        })
    }

    setQuery(s: string) {
        this.query = s.trim();
        if (this.query.length === 0) {
            this.loadSKUs();
            return;
        }
        setTimeout(() => {
            this.searchId += 1;
            const fetchId = this.searchId;
            this.isSearching = true;
            this.loadSKUs()
                .then(res => {
                    if (fetchId === this.searchId) {
                        runInAction(() => {  this.isSearching = false })
                    }
                });
        }, 800);
    }

    get skuSearchList() {
        return this.skuMasters.value;
    }

    get categoryList() {
        return this.categories.value.data;
    }

    async getCategories(): Promise<void> {
        this.isCategoriesLoading = true;
        try {
        const response = await APIService.Category.getCategories();
        runInAction(() => {
            this.categoriesData = response.data;
            this.isCategoriesLoading = false;
        });
        } catch (error) {
        console.error("Error fetching categories:", error);
        runInAction(() => {
            this.isCategoriesLoading = false;
        });
        }
    }

    async deleteCategory(categoryToDelete: ICategory): Promise<void> {
    this.isCategoriesLoading = true;
    try {
        if (categoryToDelete.inverseParentCategory && categoryToDelete.inverseParentCategory.length > 0) {
            throw new Error('Cannot delete category with subcategories');
        }

        await APIService.Category.deleteCategory(categoryToDelete, this.categoriesData);

        runInAction(() => {
            this.categoriesData = this.categoriesData.filter(cat => cat.id !== categoryToDelete.id);
            // If the category has a parent, remove it from the parent's inverseParentCategory
            if (categoryToDelete.parentCategoryId) {
                const parentCategory = this.categoriesData.find(cat => cat.id === categoryToDelete.parentCategoryId);
                if (parentCategory && parentCategory.inverseParentCategory) {
                    parentCategory.inverseParentCategory = parentCategory.inverseParentCategory.filter(subCat => subCat.id !== categoryToDelete.id);
                }
            }
            this.isCategoriesLoading = false;
        });

        this.showMessage({
            successMsg: 'Category deleted successfully.',
            errorMsg: 'Failed to delete category.',
        });
    } catch (error) {
        console.error("Error deleting category:", error);
        runInAction(() => {
            this.isCategoriesLoading = false;
        });
        throw error;
    }
}

    editCategory = async (formData: FormData) => {
        try {
            console.log(`Editing category`);

            const response = await APIService.Category.updateCategory(formData, this.categoriesData);

            if (response && response.data) {
                runInAction(() => {
                    this.categoriesData = response.data;
                });

                this.showMessage({
                    successMsg: 'Category updated successfully.',
                    errorMsg: 'Failed to update category.',
                });
                return response.data.find(cat => cat.id === formData.get('id'));
            }
            throw new Error('Invalid response format');
        } catch (error) {
            console.error('Error updating category:', error);
            throw error;
        }
    }

    async loadSubcategories(category: ICategory): Promise<void> {
        if (!category.inverseParentCategory || category.inverseParentCategory.length === 0) {
        try {
            const response = await APIService.Category.getCategories(`refNo=${category.refNo}`);
            runInAction(() => {
                if(response.data.length >0){
                    category.inverseParentCategory = response.data[0].inverseParentCategory;
                }
          
            });
            // Recursively load subcategories for each child
            for (const subcategory of category.inverseParentCategory) {
            await this.loadSubcategories(subcategory);
            }
        } catch (error) {
            console.error(`Error fetching subcategories for ${category.refNo}:`, error);
        }
        }
    }

   addNewCategory = async (formData: FormData) => {
        try {
            const parentRefNo = formData.get('parentRefNo') as string | null;
            const parentCategory = parentRefNo ? this.categoriesData.find(cat => cat.refNo === parentRefNo) : null;
            
            const response = await APIService.Category.addCategory(formData, this.categoriesData);
            
            if (response && response.data) {
                const newCategory = response.data[response.data.length - 1]; // Assuming the new category is added at the end
                runInAction(() => {
                    if (parentCategory) {
                        if (!parentCategory.inverseParentCategory) {
                            parentCategory.inverseParentCategory = [];
                        }
                        parentCategory.inverseParentCategory.push(newCategory);
                    } else {
                        this.categoriesData.push(newCategory);
                    }
                });
                
                this.showMessage({
                    successMsg: 'Category added successfully.',
                    errorMsg: 'Failed to add category.',
                });
                return newCategory;
            }
            throw new Error('Invalid response format');
        } catch (error) {
            console.error('Error adding category:', error);
            throw error;
        }
    }


    async getParentCategory(refNo:string){
        const rootCategory =  this.categories.value.data.filter(x => x.parentCategoryId === null);
        let updatedRefNo = refNo.slice(0,refNo.length-1);
         while(updatedRefNo.length > 0){
            const values = await APIService.Category.getCategories('?refNo='+updatedRefNo);
           const result =  rootCategory.find((x)=>values.data.some (v=>v.id === x.id));
           if(result){
                return result;
           }
           updatedRefNo= updatedRefNo.slice(0,updatedRefNo.length-1);
        } 
    }

    get inventoryList() {
        return this.inventory.value.data;
    }

    get skuPanelTitle(): string {
        return (this.isNewSku || this.isNewInventory) ? 'Add new item' : 'Edit item';
    }

    get invPanelTitle(): string {
        return this.isNewInventory ? 'Add new item' : 'Edit item';
    }

    get isNewSku(): boolean {
        return !this.currentSKUMaster;
    }

    get isNewInventory(): boolean {
        return !this.currentInventory;
    }

    addNewSku() {
        this.showSkuPanel = true;
        this.currentSKUMaster = undefined;
    }

    editSku(sku: ISkuMaster) {
        this.showSkuPanel = true;
        this.currentSKUMaster = sku;
    }

    resetSkuForm() {
        this.showSkuPanel = false;
        this.currentSKUMaster = undefined;
    }

    addNewInventory(sku: ISkuMaster) {
        this.showInventoryPanel = true;
        this.currentInventory = undefined;
        this.currentSKUMaster = sku;
    }

    editInventory(inv: IInventoryMaster) {
        this.showInventoryPanel = true;
        this.currentInventory = inv;
        this.loadWarehouseInventory(inv.sku)
    }

    resetInventoryForm() {
        this.showInventoryPanel = false;
        this.currentInventory = undefined;
    }

    get isLoading(): boolean {
        return this.categories.isLoading || this.skuMasters.isLoading || this.isSearching;
    }

    get isLoadingInventory(): boolean {
        return this.inventory.isLoading;
    }

    setCategory(category?: ICategory) {
        this.currentCategory = category;
    }

    setSKUMaster(skuMaster?: ISkuMaster) {
        this.currentSKUMaster = skuMaster;
    }

    setInventory(inv?: IInventoryMaster) {
        this.currentInventory = inv;
    }

    showMessage({ successMsg, errorMsg }: IMessage, resetForms = true) {
        if (this.skuMasters.error || this.inventory.error) {
            message.error(errorMsg)
            return;
        }
        if (resetForms) {
            this.resetSkuForm();
            this.resetInventoryForm();
        }

        message.success(successMsg)
    }

    async loadCategories() {
        await this.categories.fetch();
        await this.loadSKUs()
    }

    async loadSKUs() {
        let queryParameters = '';
        if (this.currentCategory) {
            queryParameters = `categoryRefNo=${this.currentCategory?.refNo}`
        }
        queryParameters += `search=${this.query || ''}`;
        await this.skuMasters.fetch(queryParameters)
    }

    async removeSKUs(item: ISkuMaster, soft = true) {
        const hide = message.loading('Deleting item...', 0);
        let queryParameters = `soft=${soft ? 'true' : 'false'}`
        await this.skuMasters.remove(item, queryParameters);
        if (this.skuMasters.error) {
            message.error('Could not delete item. An error occurred or item is already part of an order.')
        } else {
            this.resetSkuForm();
        }
        hide();
    }

    loadInventory() {
        let queryParameters = '';
        if (this.currentSKUMaster) {
            queryParameters = `parentSku=${this.currentSKUMaster?.sku}`
        }

        this.inventory.fetch(queryParameters)
    }

    async removeInventory(item: IInventoryMaster, soft = true) {
        const hide = message.loading('Deleting item...', 0);
        let queryParameters = `soft=${soft ? 'true' : 'false'}`
        await this.inventory.remove(item, queryParameters)
        if (this.inventory.error) {
            message.error('Could not delete item. An error occurred or item is already part of an order.')
        } else {
            this.resetInventoryForm();
        }
        hide();
    }

    async searchAtrribute(query: string): Promise<DebounceSelectProp[]> {
        await this.attributes.fetch(`search=${query || ''}`);

        return this.attributes.value.data.map(
            attr => ({
                key: attr.refNo,
                value: attr.refNo,
                label: attr.description,
            })
        );
    }

    async searchCategory(query: string): Promise<DebounceSelectProp[]> {
        const response = await APIService.Category.getCategories(`search=${query || ''}`);

        return response.data.map(
            attr => ({
                key: attr.refNo,
                value: attr.id,
                label: attr.name,
            })
        );
    }

    async searchCustomer(query: string, isAccountSearch = false, pageNumber = 1, pageSize = 25): Promise<DebounceSelectProp[]> {
        let queryParameters = `pageNumber=${pageNumber}&pageSize=${pageSize}&`;
        queryParameters += `search=${query || ''}`;
        const response = await APIService.Client.getClients(queryParameters);

        // TODO: filter from backend
        if (isAccountSearch) {
            return response.data.filter(x => x.accountNumber && x.accountNumber.trim().length > 0).map(
                attr => ({
                    key: attr.id,
                    value: attr.accountNumber,
                    label: `${attr.accountNumber} (${attr.userAuth.emailAddress})`
                })
            );
        }

        return response.data.map(
            attr => ({
                key: attr.id,
                value: attr.id,
                label: `${attr.userAuth.emailAddress} ${attr.accountNumber ?? ''}`
            })
        );
    }

    // search using <Select /> Component
    async searchStock(query: string, setResponse?: (s: ISkuMaster[]) => void): Promise<DebounceSelectProp[]> {
        const response = await APIService.SKUMaster.getSkuMasters(`search=${query || ''}`);
        setResponse && setResponse(response.data);
        return response.data.map(
            attr => ({
                key: attr.sku,
                value: attr.sku,
                label: attr.sku,
            })
        );
    }

    async searchInventoryMaster(query: string, setResponse?: (s: IInventoryMaster[]) => void): Promise<DebounceSelectProp[]> {
        const response = await APIService.Inventory.getInventoryMasters(`search=${query || ''}`);
        setResponse && setResponse(response.data);
        return response.data.map(
            attr => ({
                key: attr.sku,
                value: attr.sku,
                label: attr.sku,
            })
        );
    }

    async searchComplementaryItems(query: string, setValueAsId = false): Promise<DebounceSelectProp[]> {
        const response = await APIService.SKUMaster.getSkuMasters(`search=${query || ''}`);

        return response.data.map(
            attr => ({
                key: attr.sku,
                value: setValueAsId ? attr.id : attr.sku,
                label: attr.productName,
            })
        );
    }

    async searchProductClasses(query: string): Promise<DebounceSelectProp[]> {
        const response = await APIService.ProductClass.getProductClasses(`search=${query || ''}`);

        return response.data.map(
            attr => ({
                key: attr.name,
                value: attr.id,
                label: attr.name,
            })
        );
    }

    async searchStockUoms(query: string): Promise<DebounceSelectProp[]> {
        const response = await APIService.StockUom.getStockUoms(`search=${query || ''}`);

        return response.data.map(
            attr => ({
                key: attr.id,
                value: attr.id,
                label: attr.name,
            })
        );
    }

    async loadWarehouseInventory(sku: string) {
        this.isLoadingWareHouseInvetory = true;
        const response = await APIService.WarehouseInventory.getAll(`masterStockSku=${sku || ''}`);
        this.currentWarehouseInventory = response.data;
        this.isLoadingWareHouseInvetory = false;
    }

    // add sku master from api
    async onSKUFormSubmit(sku: FormData) {
        try {
            if (this.currentSKUMaster) {
                await this.skuMasters.update(sku);
                this.showMessage({
                    successMsg: 'SKU updated.',
                    errorMsg: 'Could not update SKU.',
                });
            } else {
                await this.skuMasters.add(sku)
                this.showMessage({
                    successMsg: 'SKU added.',
                    errorMsg: 'Could not add SKU.',
                });
            }
        } catch (e) {
            console.log('Error updating/adding SKU', e);
        }

        await this.loadSKUs();
    }

    // add inventory from api
    onInventoryFormSubmit = async (inv: FormData) => {
        try {
            if (this.currentInventory) {
                await this.inventory.update(inv);
                this.showMessage({
                    successMsg: 'Inventory updated.',
                    errorMsg: 'Could not update inventory.',
                }, false);
            } else {
                await this.inventory.add(inv)
                this.showMessage({
                    successMsg: 'Inventory added.',
                    errorMsg: 'Could not add inventory.',
                }, false);
            }
        } catch (e) {
            console.log('Error updating/adding branches', e);
        }

        this.showInventoryPanel = false;
        await this.loadInventory()
    }


    async removeBranch() {
        try {
            if (this.currentSKUMaster) {
                await this.skuMasters.remove(this.currentSKUMaster)
            }

            this.showMessage({
                successMsg: 'SKU deactivated.',
                errorMsg: 'Could not deactivate SKU.',
            });

            this.resetSkuForm();
        } catch (e) {
            console.log('Error deleting SKU', e);
        }
    }

    updateSkuMasterEditorContent = async (id: string, editorContent: string, editorContentHtml: string) => {
        try {
            const response = await updateEditorContent(id, editorContent, editorContentHtml); // Adjusted to include HTML content
            runInAction(() => {
                const index = this.skuMasters.value.data.findIndex(sku => sku.id === id);
                if (index !== -1) {
                    this.skuMasters.value.data[index].editorContent = editorContent;
                    // Assuming you have a corresponding property for HTML content
                    this.skuMasters.value.data[index].editorContentHtml = editorContentHtml;
                }
                message.success('Editor content updated successfully.');
            });
        } catch (error) {
            console.error('Failed to update editor content:', error);
            message.error('Failed to update editor content.');
        }
    };
}

export default new InventoryStore();
