import { observable, action, computed, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { addBranch, deleteBranch, getBranches, updateBranch } from "../services/api-service/branch";
import { IAddress, IBranch, IBranchCod, IMessage, IPostalAddress } from "../utils/interfaces";
import { message } from "antd";
import { ResponseFormat } from "../utils/types";
import { APIService, copyObjects } from "../services";
import { DebounceSelectProp } from "../components/Search/DebounceSearch";
import { addBranchCod, deleteBranchCod, getBranchCods, updateBranchCod } from "../services/api-service/branch-cod";
export class BranchStore {
    branches = new LoadManager<IBranch>({ data: [] }, getBranches, addBranch, deleteBranch, updateBranch);
    codAccounts = new LoadManager<IBranchCod>({ data: [] }, getBranchCods, addBranchCod, deleteBranchCod, updateBranchCod);

    query = "";
    showPanel = false;
    currentBranch: IBranch | null = null;
    currentCodAccount?: IBranchCod;
    activeTab = "0"

    constructor() {
        makeObservable(this, {
            branches: observable,
            codAccounts: observable,
            query: observable,
            setQuery: action,
            getBranches: computed,
            showPanel: observable,
            currentBranch: observable,
            currentCodAccount: observable,
            panelTitle: computed,
            addNew: action,
            editBranch: action,
            editBranchCod: action,
            addBranchCod: action,
            isLoading: computed,
            isLoadingCod: computed,
            loadBranches: action,
            searchBranches: action,
            onFormSubmit: action,
            onCodFormSubmit: action,
            removeBranch: action,
            activeTab: observable,
            setActiveTab: action,
            addCodAccount: action,
            addAddress: action,
            addPostalAddress: action,
            searchWarehouses: action,
        })
    }

    setQuery(s: string) {
        this.query = s.trim();
    }

    setActiveTab(key: string) {
        this.activeTab = key;
    }

    get getBranches(): ResponseFormat<IBranch> {
        if (this.query.length === 0) {
            return this.branches.value;
        } else {
            const data = this.branches.value.data.filter(brnch => (
                String(brnch.refNo).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                || String(brnch.branchName).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                || String(brnch.areaOfService).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                || String(brnch.manager).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                || String(brnch.telNumber).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                || String(brnch.faxNumber).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                || String(brnch.emailAddress).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                || String(brnch.salesEmailAddress).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                || String(brnch.embroideryEmail).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                || String(brnch.codAccount?.bankName).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                || String(brnch.sysproCompany).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
            ));

            return {
                data: data,
            }
        }
    }

    get panelTitle(): string {
        return this.isNew ? 'Add new branch' : 'Edit branch';
    }

    get isNew(): boolean {
        return !this.currentBranch;
    }

    addNew() {
        this.showPanel = true;
        this.currentBranch = null;
    }

    editBranch(branch: any) {
        this.showPanel = true;
        this.currentBranch = branch;
    }

    editBranchCod(cod?: IBranchCod) {
        this.currentCodAccount = cod;
    }

    addBranchCod(): void {
        this.currentCodAccount = undefined;
    }

    resetForm() {
        this.showPanel = false;
        this.currentBranch = null;
        this.currentCodAccount = undefined;
        this.setActiveTab("0");
    }

    get isLoading(): boolean {
        return this.branches.isLoading;
    }

    get isLoadingCod(): boolean {
        return this.codAccounts.isLoading;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.branches.error || this.codAccounts.error) {
            message.error(errorMsg)
        } else {
            message.success(successMsg)
        }
    }
    async searchWarehouses(query: string): Promise<DebounceSelectProp[]> {
        const response = await APIService.SysproWarehouse.getAll();

        return response.data.map(
            attr => ({
                key: attr.name,
                value: attr.id,
                label: attr.name,
            })
        );
    }

    // Load branches from api
    loadBranches() {
        this.branches.fetch()
    }

    async searchBranches(query: string): Promise<DebounceSelectProp[]> {
        const response = await APIService.Branch.getBranches();

        return response.data.map(
            attr => ({
                key: attr.refNo,
                value: attr.id,
                label: attr.branchName,
            })
        );
    }

    // add branches from api
    onFormSubmit = async (branch: IBranch) => {
        let updatedBody: IBranch | undefined;

        try {
            if (this.currentBranch) {
                const updatedBody = copyObjects(this.currentBranch, branch);
                await this.branches.update(updatedBody);
                this.showMessage({
                    successMsg: 'Branch updated.',
                    errorMsg: 'Could not update branch.',
                });
            } else {
                await this.branches.add(branch)
                updatedBody = this.branches.value.data.find(x => x.refNo === branch.refNo);
                this.setActiveTab("1");
                this.showMessage({
                    successMsg: 'Branch added.',
                    errorMsg: 'Could not add branch.',
                });
            }

            this.editBranch(updatedBody);
            this.showPanel = false;
        } catch (e) {
            console.log('Error updating/adding branches', e);
        }
    }

    async onCodFormSubmit(account: IBranchCod) {
        let updatedBody: IBranchCod | undefined = account;

        if (this.currentCodAccount) {
            updatedBody = copyObjects(this.currentCodAccount, account);
            await this.codAccounts.update(updatedBody);
            this.showMessage({
                successMsg: 'Branch COD updated.',
                errorMsg: 'Could not update branch COD.',
            });
        } else {
            await this.codAccounts.add(account);
            updatedBody = this.codAccounts.value.data[this.codAccounts.value.data.length - 1];
            this.showMessage({
                successMsg: 'Branch COD  added.',
                errorMsg: 'Could not add branch COD.',
            });
        }

        this.editBranchCod(updatedBody);
        return updatedBody;
    }

    addCodAccount(cod?: IBranchCod) {
        if (cod) {

            let body = copyObjects(this.currentBranch, {
                codAccountId: cod.id,
                codAccount: cod,
            });

            this.onFormSubmit(body)
        }
    }

    addAddress(address?: IAddress) {
        if (address) {
            let body = copyObjects(this.currentBranch, {
                addressId: address.id,
                address: address,
            });

            this.onFormSubmit(body)
        }
    }

    addPostalAddress(postalAddress?: IPostalAddress) {
        if (postalAddress) {
            let body = copyObjects(this.currentBranch, {
                postalAddressId: postalAddress.id,
                postalAddress: postalAddress,
            });

            this.onFormSubmit(body)
        }
    }

    async removeBranch() {
        try {
            if (this.currentBranch) {
                await this.branches.remove(this.currentBranch)
            }

            this.showMessage({
                successMsg: 'Branch deactivated.',
                errorMsg: 'Could not deactivate branch.',
            });

            this.resetForm();
        } catch (e) {
            console.log('Error deleting branches', e);
        }
    }

}
