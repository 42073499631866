import { Button, Dropdown, Input, Table } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { Spinners } from "..";
import { Store } from "../../stores/stores";

const SurveyResponseTable = observer(() => {
    const { clientsStore } = Store;

    const columns = [
        {
            title: "#",
            dataIndex: "stockCode",
        },
        {
            title: "Email Address",
            dataIndex: "emailAddress",
        },
        {
            title: "View",
            dataIndex: "",
        },
    ];


    return (
        <Spinners.CustomSpinner
            title="Getting Customer Pricing"
            isSpinning={clientsStore.isLoading}
        >
            <div className='pageheader-container'>
                <h1 className='header'>Responses</h1>
                <div className='pageheader-options'>
                    <div className="pageheader-seperator">
                        <Dropdown
                            overlay={[] as any}
                            className='pageheader-options-categories'
                            disabled={true}
                        >
                            <Button className='dropdown-btn'>
                                Filters
                            </Button>
                        </Dropdown>
                    </div>

                    <Input
                        size='large'
                        style={{ margin: 0 }}
                        className='pageheader-options-search contract-pricing-search'
                        placeholder="Search list..."
                        // defaultValue={clientsStore.pricingQuery}
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        onChange={(_) => {
                            // clientsStore.setPricingQuery(_.target.value)
                        }}
                    />
                </div>
            </div>

            <Table
                rowKey='id'
                className='custom-table  contractpricing-table'
                columns={columns}
                // dataSource={[...pricingList]}
                pagination={false}
            />
        </Spinners.CustomSpinner >
    );
});

export default SurveyResponseTable;
