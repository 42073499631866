import { Avatar, Input, Table } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Drawers, ExpandedTables, Forms, Spinners } from "../../../components";
import { Store } from "../../../stores/stores";
import "./style.css";
import { PlatformRights } from "../../../utils/enums";
import { Builder } from "./Builder";

export const HRManagement = observer(() => {
    const { surveyStore } = Store;

    const survey = surveyStore.filteredHRSurveys.data;

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            fixed: 'left' as 'left',
            width: 30,
            render: (text: any, record: any, index: any) => index + 1
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: 200,
        },
        {
            title: 'Opening Message',
            dataIndex: 'openingMessage',
            width: 200,
        },
        {
            key: 'edit',
            fixed: 'right' as 'right',
            width: 80,
            render: (text: any, record: any) => (
                <>
                    <Buttons.Text
                        text='Edit'
                        onClick={() => {
                            surveyStore.editSurvey(record);
                        }}
                    />
                    <Buttons.Text
                        text='Build'
                        onClick={() => {
                            surveyStore.editSurvey(record, 'build');
                        }}
                    />
                    <Buttons.Text
                        text='View'
                        onClick={() => {
                            surveyStore.editSurvey(record, 'responses');
                        }}
                    />
                </>
            )
        },
    ];

    React.useEffect(() => {
        surveyStore.loadSurvey()
    }, [surveyStore]);

    return (
        <main id="home" className='home-content-page'>
            <div className='pageheader-container'>
                <h1 className='header'>Survey Management</h1>
                <div className='pageheader-options'>
                    <Input
                        size='large'
                        className='pageheader-options-search'
                        placeholder="Search list..."
                        defaultValue={surveyStore.query}
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        onChange={(_) => {
                            surveyStore.setQuery(_.target.value)
                        }}
                    />
                    <Buttons.Small
                        text={'Add new'}
                        onClick={() => {
                            surveyStore.addNew()
                        }}
                    />
                </div>
            </div>

            <div className='custom-table-container'>
                <Spinners.CustomSpinner
                    title={'Getting Survey'}
                    isSpinning={surveyStore.isLoading}
                >
                    <Table
                        dataSource={[...survey]}
                        columns={columns}
                        className='custom-table'
                        rowKey='id'
                        scroll={{ x: 1400 }}
                        pagination={{
                            style: { margin: '16px' }
                        }}
                    />
                </Spinners.CustomSpinner>

            </div>

            <Drawers.CustomDrawer
                title={surveyStore.panelTitle}
                visible={surveyStore.showPanel}
                setVisible={(_) => surveyStore.resetForm()}
                children={() => (
                // TODO: form survey
                    <Forms.NewSurvey
                        survey={surveyStore.currentSurvey ??  undefined}
                        isLoading={surveyStore.isLoading}
                        onSurveyDelete={() => surveyStore.removeSurvey()}
                        onFormFinish={(survey) => surveyStore.onFormSubmit(survey)}
                    />
                )}
            />
            <Drawers.CustomDrawer
                title={surveyStore.panelTitle}
                visible={surveyStore.showBuildPanel}
                setVisible={(_) => surveyStore.resetForm()}
                width="95%"
                children={() => (
                    <Builder />
                )}
            />

            <Drawers.CustomDrawer
                title={surveyStore.panelTitle}
                visible={surveyStore.showResponses}
                setVisible={(_) => surveyStore.resetForm()}
                width="95%"
                children={() => (
                    <ExpandedTables.SurveyResponsesTable />
                )}
            />
        </main>
    );
});
