import React from 'react';
import { Table, Space } from 'antd';
import { CheckOutlined, CloseOutlined, MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Buttons } from "..";
import { ICategory } from "../../utils/interfaces";

interface ExpandedCategoryTableProps {
  category: ICategory;
  onEdit: (category: ICategory) => void;
  onDelete: (refNo: string) => void;
  onExpand: (expanded: boolean, record: ICategory) => void;
  onAddSubcategory: (parentCategory: ICategory) => void;
}

const ExpandedCategoryTable: React.FC<ExpandedCategoryTableProps> = ({ 
  category, 
  onEdit, 
  onDelete, 
  onExpand, 
  onAddSubcategory 
}) => {
  const columns = [
    {
      title: 'Category',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text: string) => <div className='long-text'>{text}</div>,
    },
    {
      title: 'Featured',
      dataIndex: 'featured',
      key: 'featured',
      render: (featured: boolean | null) => (
        featured ? (
          <CheckOutlined style={{ color: 'green' }} />
        ) : (
          <CloseOutlined style={{ color: 'red' }} />
        )
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (active: boolean | null) => (
        active ? (
          <CheckOutlined style={{ color: 'green' }} />
        ) : (
          <CloseOutlined style={{ color: 'red' }} />
        )
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right' as 'right',
      width: 250,
      render: (_: any, record: ICategory) => (
        <Space size="middle">
          <Buttons.Text
            text='Add Subcategory'
            onClick={() => onAddSubcategory(record)}
          />
          <Buttons.Text
            text='Edit'
            onClick={() => onEdit(record)}
          />
          <Buttons.Text
            text='Delete'
            onClick={() => onDelete(record.refNo)}
            disabled={record.inverseParentCategory && record.inverseParentCategory.length > 0}
          />
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={category.inverseParentCategory || []}
      rowKey="refNo"
      pagination={false}
      key={`${category.refNo}-${category.inverseParentCategory?.length || 0}`}
      className='expanded-table expanded-table-category'
      expandable={{
        expandedRowRender: (record) => (
          <ExpandedCategoryTable
            category={record}
            onEdit={onEdit}
            onDelete={onDelete}
            onExpand={onExpand}
            onAddSubcategory={onAddSubcategory}
          />
        ),
        onExpand: onExpand,
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <MinusSquareOutlined onClick={e => onExpand(record, e)} />
          ) : (
            <PlusSquareOutlined onClick={e => onExpand(record, e)} />
          )
      }}
    />
  );
};

export default ExpandedCategoryTable;