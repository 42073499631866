import { Form, FormInstance, Input, InputNumber, message, Switch } from "antd";
import React from "react";
import { Buttons, Search } from "..";
import { EmailService, copyObjects } from "../../services";
import { IBranch, ISysproWarehouse } from "../../utils/interfaces";
import { PopupConfirm } from "../Modals";
import { DebounceSelectProp } from "../Search/DebounceSearch";

type NewBranchFormProp = {
    branch?: IBranch | null,
    onFormFinish: (branch: IBranch) => void
    onBranchDelete: () => void
    onWarehouseSearch: (query: string) => Promise<any[]>;
    isLoading: boolean
};

const NewBranchForm = ({ branch, isLoading, onBranchDelete, onFormFinish, onWarehouseSearch }: NewBranchFormProp) => {
    const formRef = React.createRef<FormInstance>();
    const warehouseRef = React.useRef<Partial<ISysproWarehouse>>({});

    const initialValues = copyObjects(branch, { sysproWarehouseName: branch?.sysproWarehouse?.name });

    const validateMessages = {
        required: "Field is required!",
    };

    const BranchSelect = ({ ...props }) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={onWarehouseSearch}
            onSelect={(value: any, option: any) => {
                warehouseRef.current.name = option.key as string;
            }}
            {...props}
        />
    );

    const branchFormData = [
        { name: 'refNo', label: "Ref #", component: Input, required: true },
        { name: 'branchName', label: "Branch name", component: Input, required: true },
        { name: 'areaOfService', label: "Area Of Service", component: Input, required: true },
        { name: 'manager', label: "Manager", component: Input, required: true },
        { name: 'telNumber', label: "Tel. number", component: Input, required: true },
        { name: 'faxNumber', label: "Fax number", component: Input, required: true },
        { name: 'emailAddress', label: "Email", component: Input, required: true },
        { name: 'salesEmailAddress', label: "Sales email", component: Input, required: true },
        { name: 'embroideryEmail', label: "Embroidery email", component: Input, required: true },
        { name: 'sysproCompany', label: "SysPro Company", component: Input, required: true },
        { name: 'quoteThreshold', label: "Quote Threshold", component: InputNumber, required: false },
        { name: 'sysproWarehouseName', label: "SysPro Warehouse", component: BranchSelect, required: true },
        { name: 'active', label: "Active", component: Switch, valuePropName: "checked", required: false },
        // { name: 'location', label: "Location", component: Input, required: true },
    ];

    const emailsValid = (emails: string[]) => {
        let inValid = emails.filter(x => !EmailService.isEmailValid(x));
        return inValid.length === 0;
    };

    const onFinish = (values: any) => {
        const emails = [
            values.emailAddress,
            values.salesEmailAddress,
            values.embroideryEmail,
        ];

        if (emailsValid(emails)) {
            const newBranch: IBranch = {
                ...values,
                sysproWarehouseId: initialValues.sysproWarehouseName === values.sysproWarehouseName ? branch?.sysproWarehouseId : values?.sysproWarehouseName,
                sysproWarehouse: {
                    name: warehouseRef.current?.name ? warehouseRef.current?.name : initialValues?.sysproWarehouse?.name,
                }
            }
            onFormFinish(newBranch);
        } else {
            message.error('Enter a valid email address');
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        if (!branch) {
            message.error('Could not add new branch.')
        } else {
            message.error('Could not update branch.')
        }
    };


    return (
        <>
            {branch &&
                <div className='form-delete-container'>
                    <PopupConfirm
                        isLoading={isLoading}
                        onConfirm={() => onBranchDelete()}
                    />
                </div>
            }

            <Form
                ref={formRef}
                name='new-branch'
                className='custom-form'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={initialValues}
            >
                {branchFormData.map(({ component: Component, ...item }, index) =>
                    <Form.Item
                        key={index}
                        rules={[{ required: item.required }]}
                        {...item}
                    >
                        <Component />
                    </Form.Item>
                )}

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={branch ? 'Update branch' : 'Add branch'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </Form>
        </>
    );
};

export default NewBranchForm;
