/* eslint-disable react/jsx-pascal-case */
import { CheckOutlined, CloseOutlined, CloudDownloadOutlined, StarOutlined } from "@ant-design/icons";
import { Input, Table, Tabs, message } from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Drawers, ExpandedTables, Forms, Spinners } from "../../../components";
import { Store } from "../../../stores/stores";
import { IClient, IClientAddress } from "../../../utils/interfaces";
import "./style.css";
import { FiUsers } from "react-icons/fi";

export const CustomerManagement = observer(() => {
    const { addressesStore, branchesStore, clientsStore ,LinkedAccountsStore,MFAStore, CODAccountNumberStore} = Store;

    const activeTab = clientsStore.activeTab;
    const clients = clientsStore.getClients.data;
    const pagination = clientsStore.pagination;
    const showPricingPanel = clientsStore.showPricingPanel;


    // Function to download CSV
    const downloadCSV = async () => {
        await clientsStore.exportClientCSV();
    };

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            fixed: 'left' as 'left',
            width: 80,
            render: (text: any, record: any, index: number) =>
                (index + 1) + ((pagination.PageNumber - 1) * pagination.PageSize)
        },
        {
            title: "SysPro Id",
            dataIndex: "sysproId",
        },

        {
            title: "First name",
            dataIndex: "first_name",
            render: (text: any, record: IClient) => record?.userAuth?.firstName
        },
        {
            title: "Last name",
            dataIndex: "last_name",
            render: (text: any, record: IClient) => record?.userAuth?.lastName
        },
        {
            title: "Email",
            dataIndex: "email",
            render: (text: any, record: IClient) => record?.userAuth?.emailAddress
        },
        {
            title: "Registered",
            dataIndex: "registered",
            render: (text: any, record: IClient) => record?.userAuth?.registeredAt
        },
        {
            title: "Claimed account",
            dataIndex: "claimed_account_number",
            render: (text: any, record: IClient) => record?.claimedAccountNumber
        },
        {
            title: "Account #",
            dataIndex: "account_number",
            render: (text: any, record: IClient) => record?.accountNumber
        },
        {
            title: "Linked Account",
            dataIndex: "linked_account",
            render: (text: any, record: IClient) => (
              record?.linkedAccounts?.length > 0 ? (
                record?.linkedAccounts?.some((account) => account.verified === true) ? (
                  <CheckOutlined style={{ color: 'green' }} />
                ) : (
                  <CloseOutlined style={{ color: 'red' }} />
                )
              ) : (
                <span>- -</span>
              )
            )
          },
               
        {
            title: "Branch",
            dataIndex: "branch",
            render: (text: any, record: IClient) => record?.branch?.refNo
        },
        {
            title: "Billing company",
            dataIndex: "billing_company",
            render: (text: any, record: IClient) => record?.billingCompany
        },
        {
            title: "Billing Phone",
            dataIndex: "billing_phone",
            render: (text: any, record: IClient) => record?.billingPhone
        },
        {
            title: "Migration status",
            dataIndex: "migrationStatus",
            render: (text: any, record: IClient) => record?.migrationStatus
        },
        {
            title: "Billing Address",
            dataIndex: "clientAddresses",
            render: (text: IClientAddress[] | null, record: any) => {
                const address = text?.find(x => !x.isShippingAddres);
                return (
                    address &&
                    <div className='custom-table-address-field'>
                        <div>{address?.address?.address1}</div>
                        <div>{address?.address?.address2}</div>
                        <div>{address?.address?.address3}</div>
                        <div>{address?.address?.city}</div>
                        <div>{address?.address?.province}</div>
                        <div>{address?.address?.country}</div>
                        <div>{address?.address?.postalCode}</div>
                    </div>
                )
            }
        },
        {
            title: "Shipping address",
            dataIndex: "clientAddresses",
            render: (text: IClientAddress[] | null, record: any) => {
                const address = text?.find(x => x.isShippingAddres);
                return (
                    address &&
                    <div className='custom-table-address-field'>
                        <div>{address?.address?.address1}</div>
                        <div>{address?.address?.address2}</div>
                        <div>{address?.address?.address3}</div>
                        <div>{address?.address?.city}</div>
                        <div>{address?.address?.province}</div>
                        <div>{address?.address?.country}</div>
                        <div>{address?.address?.postalCode}</div>
                    </div>
                )
            }
        },
        {
            title: "Communication consent",
            dataIndex: "communicationConsent",
            render: (isNew: boolean, record: any) => (
                isNew ? (
                    <CheckOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                )
            )
        },
        {
            key: 'edit',
            fixed: 'right' as 'right',
            width: 90,
            render: (text: any, record: any) => (
                <Buttons.Text
                    text='Edit'
                    onClick={() => clientsStore.editClient(record)
                    }
                />
            )
        }
    ];

    const ClientDetails = () => (
        <Forms.NewCustomer
            client={clientsStore.currentClient}
            isLoading={clientsStore.isLoading}
            onClientDelete={() => clientsStore.removeClient()}
            onFormFinish={(branch) => clientsStore.onFormSubmit(branch)}
            onBranchSearch={(query) => branchesStore.searchBranches(query)}
            tooglePricingPanel={(_) => clientsStore.tooglePricingPanel(_)}
        />
    );
    const MFA = () => (
        <Forms.NewMFA
            userId = {clientsStore.currentClient?.userAuthId !}
            MFAStatus={MFAStore.currentMFA}
            isLoading={MFAStore.isLoading}
            onFormFinish={(status) => MFAStore.addMFAStatus(status,[])}
        />
    );

    const AddressDetails = () => {
        addressesStore.editAddress(clientsStore.currentClient?.clientAddresses?.find(x => !x.isShippingAddres)?.address);
        return (
            <Forms.NewAddress
                address={addressesStore.currentAddress}
                isLoading={addressesStore.isLoading || clientsStore.clientAddresses.isLoading}
                onFormFinish={async (address) => {
                    const newAddress = await addressesStore.onFormSubmit(address);
                    clientsStore.addAddress(newAddress);
                }}
            />
        );
    }

    const ShippingAddressDetails = () => {
        addressesStore.editAddress(clientsStore.currentClient?.clientAddresses?.find(x => x.isShippingAddres)?.address);
        return (
            <Forms.NewAddress
                address={addressesStore.currentAddress}
                isLoading={addressesStore.isLoading || clientsStore.clientAddresses.isLoading}
                onFormFinish={async (address) => {
                    const newAddress = await addressesStore.onFormSubmit(address);
                    clientsStore.addAddress(newAddress, true);
                }}
            />
        );
    }

    const tabsPane = [
        { key: "0", tab: "Details", component: ClientDetails },
        { key: "1", tab: "Billing Address", component: AddressDetails, disabled: clientsStore.isNew },
        { key: "2", tab: "Shipping Address", component: ShippingAddressDetails, disabled: clientsStore.isNew },
        { key: "3", tab: "MFA Option", component:MFA, disabled: clientsStore.isNew },
    ];

    React.useEffect(() => {
        clientsStore.loadClients(1)
    }, [clientsStore])

    return (
        <main id="customer-management" className='home-content-page'>
            <div className='pageheader-container'>
                <h1 className='header' style={{ marginRight: '25px' }}>Customer Management</h1>
                <div className='pageheader-options'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    {/* COD Accounts*/}
                    <Buttons.Small
                        className='dropdown-btn'
                        icon={<StarOutlined />} // Use the FiUsers icon for linked accounts
                        text="COD"
                        onClick={() => {
                            CODAccountNumberStore.togglePanel(true);

                        }}
                        style={{ marginRight: '5px' }} // Add space to the right of the button
                    />
                    {/* Download CSV button */}
                    <Buttons.Small
                        className='dropdown-btn'
                        icon={<FiUsers />} // Use the FiUsers icon for linked accounts
                        text="Linked Accounts"
                        onClick={() => {
                            LinkedAccountsStore.togglePanel(true);

                        }}
                        style={{ marginRight: '5px' }} // Add space to the right of the button
                    />

                    <Buttons.Small
                        className='dropdown-btn'
                        icon={<CloudDownloadOutlined />}
                        text="CSV"
                        onClick={() => {
                            downloadCSV();
                        }}
                    />
                </div>

                    <Input
                        size='large'
                        className='pageheader-options-search'
                        placeholder="Search list..."
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        onChange={(_) => {
                            clientsStore.setQuery(_.target.value)
                        }}
                    />

                    <Buttons.Small
                        text={'Add new'}
                        onClick={() => {
                            clientsStore.addNew();
                        }}
                    />
                </div>
            </div>

            <div className='custom-table-container'>
                <Spinners.CustomSpinner
                    title={'Getting Customers'}
                    isSpinning={clientsStore.isLoading}
                >
                    <Table
                        dataSource={[...clients]}
                        columns={columns}
                        className='custom-table'
                        rowKey='id'
                        scroll={{ x: 3500 }}
                        pagination={{
                            pageSize: pagination.PageSize,
                            current: pagination.PageNumber,
                            total: pagination.TotalRecords,
                            pageSizeOptions: ["100", "500", "1000"],
                            style: { margin: '16px' },
                            onChange: (page, size) => {
                                clientsStore.loadClients(page, size);
                            }
                        }}
                    />
                </Spinners.CustomSpinner>

            </div>

            <Drawers.CustomDrawer
                title={clientsStore.panelTitle}
                visible={clientsStore.showPanel}
                setVisible={(_) => clientsStore.resetForm()}
                children={() => (
                    <>
                        <Tabs
                            activeKey={activeTab}
                            defaultActiveKey="1"
                            className='custom-tabs'
                            size='large'
                            onChange={(_) => clientsStore.setActiveTab(_)}
                        >
                            {tabsPane.map(({ component: Component, ...item }) =>
                                <Tabs.TabPane {...item} >
                                    <Component />
                                </Tabs.TabPane>
                            )}
                        </Tabs>

                        <Drawers.CustomDrawer
                            title={"Contract Pricing"}
                            visible={showPricingPanel}
                            setVisible={(_) => clientsStore.tooglePricingPanel(_)}
                            width={"40%"}
                            children={(props: any) => (
                                <ExpandedTables.ContractPricingTable />
                            )}
                        />
                    </>
                )}
            />
            <Drawers.linkedAccounts />
            <Drawers.CodAccountNumbers />
        </main>
    )
});
